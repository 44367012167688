.elementor_search_map {
	.wrap_search_map {
		.wrap_search {	
		    .result-title {
		    	text-align: center;
		    	font-weight: 600;
				font-size: 36px;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 80px;
		    }	
			.fields_search {
				max-width: 70%;
				margin: 0 auto;
				@media screen and (max-width: 1024px) {
					max-width: 100%;
				}
				.form_search_map {
					.wrap_content {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
						background: #FFFFFF;
						box-shadow: 0px 24px 100px rgba(0, 0, 0, 0.05);
						border-radius: 12px;
						padding: 36px 36px 32px;
						margin-bottom: 80px;

						@media screen and (max-width: 930px) {
							grid-template-columns: 1fr 1fr;
						}
						@media screen and (max-width: 680px) {
							grid-template-columns: 1fr;
						}
						.label_search {
							width: 100%;
							input {
								background: #FFFFFF;
								border: 1px solid #CACACA;
								box-sizing: border-box;
								border-radius: 10px;
								height: 56px;
								padding: 16px 20px;
								font-weight: 500;
								font-size: 18px;
								line-height: 22px;
								color: var(--heading);

								&::placeholder {
									font-weight: 400;
									font-size: 18px;
									line-height: 22px;
									color: var(--text);
									opacity: 1;
								}
							}

							&.wrap_search_start_date, &.wrap_search_end_date {
								position: relative;
								display: flex;
								align-items: center;
								justify-content: center;

								input {
									padding-left: 56px;
								}

								i {
									position: absolute;
									left: 20px;
									font-size: 24px;
									color: var(--text);
								}
							}

							select {
								height: 56px;
								border: 1px solid #CACACA;
								box-sizing: border-box;
								border-radius: 10px;
								padding: 16px 20px;
							}

							.select2 {
								height: 56px;

								.selection {
									.select2-selection--single {
										border: 1px solid #CACACA;
										box-sizing: border-box;
										border-radius: 10px;

										.select2-selection__rendered {
											padding: 16px 20px;
											font-weight: 500;
											font-size: 18px;
											line-height: 22px;
											color: var(--heading);

											.select2-selection__placeholder {
												font-weight: 400;
												font-size: 18px;
												line-height: 22px;
												color: var(--text);
											}
										}

										.select2-selection__arrow {
											top: 12px;
											right: 20px;
										}
									}
								}
							}
						}

						.ovabrw-search-guests {
							display: flex;
							align-items: center;
							background: #FFFFFF;
							border: 1px solid #CACACA;
							box-sizing: border-box;
							border-radius: 10px;
							height: 56px;
							padding: 16px 20px;

							.guests-buttons {
								display: flex;
								align-items: center;
								justify-content: space-between;
    							width: 100%;

    							.description {
    								label {
    									font-weight: 400;
										font-size: 18px;
										line-height: 22px;
										color: var(--text);
    								}
    							}

								.guests-button {
									display: flex;

									input {
										width: 28px;
										height: 28px;
										padding: 0;
										border: none;
										text-align: center;
										font-weight: 500;
										font-size: 18px;
										line-height: 22px;
										color: var(--heading);
									}

									.minus, .plus {
										width: 28px;
										height: 28px;
										display: flex;
										align-items: center;
										justify-content: center;
										background-color: #F9F9F9;
										border-radius: 4px;
										-webkit-transition: all .3s ease;
										-moz-transition: all .3s ease;
										-ms-transition: all .3s ease;
										transition: all .3s ease;

										i {
											line-height: 1;
											font-size: 15px;
											color: #5B5B5B;
											font-weight: 600;
											-webkit-transition: all .3s ease;
											-moz-transition: all .3s ease;
											-ms-transition: all .3s ease;
											transition: all .3s ease;
										}

										&:hover {
											background-color: var(--primary);
											i {
												color: #FFFFFF;
											}
										}
									}

									.minus {
										margin-right: 10px
									}

									.plus {
										margin-left: 10px;

										i {
											font-size: 13px;
										}
									}
								}
							}
						}
						.show_taxonomy {
							display: none;
						}
					}
				}
			}
			.search_result {
				.one-column {
					.products {
						display: grid;
						grid-template-columns: 1fr;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
					}
				}
				.two-column {
					.products {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
					}
				}
				.three-column {
					.products {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
						@media screen and (max-width: 768px) {
							grid-template-columns: 1fr 1fr;
						}
						@media screen and (max-width: 546px) {
							grid-template-columns: 1fr;
						}
					}
				}
				.products {
					&:before {
						display: none;
					}
					.product {
						a {
							img {
								width: 100%;
								border-radius: 5px 5px 0 0;
							}
						}
					}
				}
				.ovabrw_pagination_ajax {
					ul {
					    padding: 0px;
					    margin: 0px;
					    list-style-type: none;
					    display: flex;
					    flex-wrap: wrap;
					    justify-content: center;
					    li {
					    	cursor: pointer; 
				    	    margin: 0 6px 12px 6px;
				    	    span {
				    	    	padding: 0px 10px 0px 10px;
    							min-width: 45px;
    							font-size: 1.2em;
    							font-weight: 600;
    							color: #808080;
							    height: 42px;
							    border: 1px solid #f1f1f1;
							    background-color: #f1f1f1;
							    border-radius: 4px;
							    display: inline-flex;
							    justify-content: center;
							    align-items: center;
							    -webkit-transition: all .3s ease;
							    -o-transition: all .3s ease;
							    transition: all .3s ease;

							    i {
							    	&:before {
							    		font-size: 1.2em;
							    		font-weight: 600;
							    	}
							    }

							    &:hover {
							    	background-color: var(--primary);
				    	    		color: #fff;
							    }
				    	    }
				    	    .current {
				    	    	background-color: var(--primary);
				    	    	color: #fff;
				    	    }
					    }
					}
				}
			}
			.wrap_load_more {
				width: 100%;
				display: none;
				position: relative;
				padding: 30px 0;
				.loader {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%);
					stroke-linecap: round;
					circle {
						fill: none;
						stroke-width: 1.5;
						animation-duration: 1.5s;
						animation-iteration-count: infinite;
						animation-timing-function: linear;
						transform-origin: 25px 25px;
						will-change: transform;
						stroke: var(--primary);

						&:nth-of-type(1) {
							stroke-dasharray: 40px;
							animation-name: preloader_1;
						}

						&:nth-of-type(2) {
							stroke-dasharray: 80px;
							animation-name: preloader_2;
						}
					}

					@keyframes preloader_1 {
						100% {
							transform: rotate(360deg);
						}
					}
					@keyframes preloader_2 {
						100% {
							transform: rotate(-360deg);
						}
					}
				}
			}
			.wrap_search_radius {
				span {
					font-weight: 500;
				    font-size: 18px;
				    line-height: 23px;
				    letter-spacing: 0px;
				    color: #333333;
				}
			}
			.wrap_search_filter_title {
				display: flex;
			    justify-content: space-between;
			    align-items: center;
			    margin-top: 30px;
			    margin-bottom: 30px;
			    .results_found {
			    	margin-right: 30px;
			    	span {
			    		font-weight: 500;
					    font-size: 18px;
					    line-height: 23px;
					    letter-spacing: 0px;
					    color: #333333;
			    	}
			    }
			    #search_sort {
			    	select {
			    		border-color: #e2e2e2;
	    				border-radius: 4px;
	    				outline: none;
	    				padding: 8px 25px 8px 10px;
			    	}
			    	.select2-selection__rendered {
			    		padding: 3px 35px 3px 15px;
			    	}
			    }
			}
		}
	}
	.select2 {
		height: 100%;
		width: 100% !important;
		.select2-selection.select2-selection--single {
			height: 100%;
			min-height: 40px;
			border-color: #e2e2e2;
    		border-radius: 4px;
    		outline: none;
    		display: flex;
    		align-items: center;
    		.select2-selection__rendered {
    			padding: 0 35px 0 15px;
    			transition: all .2s ease;
    		}
    		.select2-selection__arrow {
    			top: 4px;
			    right: 10px;
	    	}
		}
	}
}
.select2-dropdown {
	border-color: #e2e2e2 !important;
}