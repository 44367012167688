.ovabrw-room-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
}

.ovabrw-room-content {
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	border-radius: 10px;
	
	.room-image {
		display: block;
		overflow: hidden;
		border-radius: 10px 10px 0 0;
    	img {
			width: 100%;
			height: 293px;
			vertical-align: middle;
			border-radius: 10px 10px 0 0;
			object-fit: cover;
			-o-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-webkit-transition: all .3s ease;
			transition: all .3s ease;
		}
    }

	&:hover {
		box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.08);

		.room-image {
            img { 
				transform: translate3d(1%, 0, 0) scale(1.05);
				-o-transition: all .3s ease;
				-ms-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
			}
		}
	}

	.room-meta {
		padding: 20px;
		border-radius: 0 0 10px 10px;
		background-color: #fff; 

		.room-price-title {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-bottom: 20px;
            .room-title {
				margin: 0;
				font-size: 26px;
				line-height: 1.2em;
				letter-spacing: 0.01em;
				font-weight: 600;
				a {
				 	color: #000;
				 	-moz-transition: all .3s ease;
				 	-webkit-transition: all .3s ease;
				 	-ms-transition: all .3s ease;
				 	transition: all .3s ease;
				 }
			}
			.room-price {
				display: inline-block;
				padding: 8px 12px;
				color: var(--primary);
				border: 2px solid var(--primary);
				background-color: transparent;
				border-radius: 4px; 
				font-size: 14px;
				line-height: 1.2em;
	            letter-spacing: 0.01em;
				font-weight: 600;
			}
		}

		.room-info {
			list-style-type: none;
			margin: 0;
			padding: 0;
			background: rgba(255, 255, 255, 0.16);
            border-radius: 6px;
			display: -webkit-box;
		    display: -ms-flexbox;
		    display: inline-flex;
		    -ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		    li {
		    	display: flex;
		    	align-items: center;
		    	font-size: 14px;
		    	color: var(--text);
		    	font-weight: 500;
                &:not(:last-child) {
                	margin-right: 15px;
                }
                i {
                	color: #063c43;
                	font-size: 22px;
                	line-height: 1;
                	margin-right: 6px;
                }
                &.square {
                	span {
                		text-transform: lowercase; ;
                	}
                }
		    }
		}
	}
    
}