.ovabrw-room-content2 {
	.wrapper-room {
		display: flex;
		display: -webkit-flex;
		background-color: #FFFFFF;
		border-radius: 12px;
		width: 100%;
		overflow: hidden;
		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.room-img {
			width: 52%;
			@media (max-width: 1024px) {
				width: 100%;
			}

			a {
				display: flex;
				align-items: center;
				height: 100%;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					max-height: 450px;
					object-fit: cover;
					object-position: center;
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;
				}
			}
		}

		.room-container {
			padding: 32px;
			width: 48%;
			position: relative;
			@media (max-width: 1024px) {
				width: 100%;
			}

			.room-price {
				position: absolute;
				top: 32px;
				right: 32px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				background-color: #FFFFFF;
				border: 2px solid #0F97AA;
				box-sizing: border-box;
				border-radius: 4px;
				padding: 8px 12px;
				@media (max-width: 767px) {
					position: static;
					margin-bottom: 16px; 
					display: inline-block;
				}

				.price {
					font-size: 18px;
					font-weight: 700;
					line-height: 21.6px;
					color: #0F97AA;
				}

				.label {
					font-size: 14px;
					font-weight: 600;
					line-height: 16.8px;
					color: #0F97AA;
				}
			}

			.room-title {
				margin: 0; 
				padding-top: 8px;
				padding-bottom: 30px;
				font-weight: 600;
				font-size: 36px;
				letter-spacing: 0.01em;
				
				a {
					color: var(--heading);
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						color: var(--primary);
					}
				}
			}

			.room-service {
				list-style-type: none;
				margin: 0;
				padding: 0 0 20px 0;
				background: rgba(255, 255, 255, 0.16);
                border-radius: 6px;
				display: -webkit-box;
			    display: -ms-flexbox;
			    display: inline-flex;
			    -ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			    li {
			    	display: flex;
			    	align-items: center;
			    	font-size: 16px;
			    	font-weight: 500;
			    	line-height: 1.2;
			    	color: var(--heading);
			    	background: #F9F9F9;
                    border-radius: 6px;
                    padding: 10px;
                    margin-bottom: 16px;
                    &:not(:last-child) {
                    	margin-right: 28px;
                    }
                    i {
                    	color: #063c43;
                    	font-size: 24px;
                    	line-height: 1;
                    	margin-right: 8px;
                    }
                    &.square {
                    	span {
                    		text-transform: lowercase; ;
                    	}
                    }
			    }
			}
			.room-short-description {
				border-top: 1px solid #cacaca;
				padding: 36px 0;
				margin: 0;
				font-weight: 500;
			}
			.room-button {
				cursor: pointer;
				transition: all .3s ease;
				display: inline-block;
				background-color: var(--primary);
                border-radius: 8px;
                padding: 17px 53px;
                font-size: 18px;
                line-height: 1.2;
                text-transform: uppercase;
                font-weight: bold;
                color: #fff;
                &:hover {
                	transition: all .3s ease;
                	background-color: #000;
                }
			}

			.room-button-link {
				a {
					display: inline-block;
					background-color: var(--primary);
	                border-radius: 8px;
	                padding: 17px 53px;
	                font-size: 18px;
	                line-height: 1.2;
	                text-transform: uppercase;
	                font-weight: bold;
	                color: #fff;
	                transition: all .3s ease;
	                -webkit-transition: all .3s ease;
	                -moz-transition: all .3s ease;
	                -ms-transition: all .3s ease;

	                &:hover {
	                	background-color: #000;
	                }
				}
			}
		}
	}

	&:not(:last-child) {
		margin-bottom: 40px;
	}

	&:hover {
		.wrapper-room {
			.room-img {
				a {
					img {
						-webkit-transition: all .3s ease;
						-moz-transition: all .3s ease;
						-ms-transition: all .3s ease;
						transition: all .3s ease;
						transform: translate3d(1%, 0, 0) scale(1.05);
						-ms-transform: translate3d(1%, 0, 0) scale(1.05);
					}
				}
			}
		}	
	}

	.room-load-more {
		width: 100%;
		display: none;
		position: relative;
		padding: 40px 0;
		.loader {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%);
			stroke-linecap: round;
			circle {
				fill: none;
				stroke-width: 1.5;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				transform-origin: 25px 25px;
				will-change: transform;
				stroke: var(--primary);

				&:nth-of-type(1) {
					stroke-dasharray: 40px;
					animation-name: preloader_1;
				}

				&:nth-of-type(2) {
					stroke-dasharray: 80px;
					animation-name: preloader_2;
				}
			}

			@keyframes preloader_1 {
				100% {
					transform: rotate(360deg);
				}
			}
			@keyframes preloader_2 {
				100% {
					transform: rotate(-360deg);
				}
			}
		}
	}

	.booking-room {
		margin-top: 72px;
		overflow: hidden;
		.booking-room-open {
			display: flex;
            @media (max-width: 1024px) {
				flex-direction: column;
			}
			.room-detail {
				width: 53.5%;
				margin-right: 32px;
				@media (max-width: 1024px) {
					width: 100%;
					margin-right: 0;
				}
				.booking-room-service {
					list-style-type: none;
					margin: 0 0 40px 0;
					padding: 28px 30px;
					background: #fff;
	                border-radius: 12px;
					display: grid;
					grid-column-gap: 40px; 
					grid-row-gap: 30px;
				    grid-template-columns: auto auto auto;
				    align-items: center;
				    @media (max-width: 767px) {
				    	grid-template-columns: auto auto;
				    }
				    @media (max-width: 460px) {
						grid-template-columns: auto;
					}
				    .item-service {
				    	display: flex;
				    	align-items: center;
				    	transition: all .3s ease; 
				    	font-size: 18px;
				    	font-weight: 500;
				    	line-height: 1.2;
				    	color: var(--heading);
	                    border-radius: 6px;
	                    i {
	                    	color: var(--primary);
	                    	font-size: 32px;
	                    	line-height: 1;
	                    	margin-right: 8px;
	                    }
				    }
				}

				.room-gallery {
					list-style-type: none;
					margin: 0 0 32px 0;
					padding: 0;
					display: grid;
					grid-gap: 16px;
				    grid-template-columns: auto auto;
				    @media (max-width: 900px) {
						grid-template-columns: auto;
					}

				    .item-gallery {
				    	.gallery-fancybox {
				    		cursor: pointer;
				    		img {
				    			width: 100%;
				    			border-radius: 12px;
				    			vertical-align: middle;
				    		}
				    	}

			    		&.gallery_blur {
							position: relative;

							.gallery-fancybox {
								.blur-bg {
									border-radius: 12px;
									position: absolute;
									width: 100%;
									height: 100%;
									top: 0;
									left: 0;
									background-color: rgba(0, 0, 0, 0.5);
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 28px;
									line-height: 34px;
									color: rgba(255, 255, 255, 0.9);
								}
							}
						}

						&.gallery_hidden {
							display: none;
						}
				    }
				}

                .room-features-wrapper {
                    padding: 36px;
                    background: #fff;
	                border-radius: 12px;
	                margin-bottom: 32px;
	                .ajax-room-features-title {
						text-transform: uppercase;
						font-size: 24px;
						font-weight: 600;
						margin: 0;
						padding-bottom: 40px;
					}

					.room-features {
						list-style-type: none;
						margin: 0;
						padding: 0;
						display: grid;
						grid-gap: 30px;
					    grid-template-columns: auto auto auto;
					    align-items: center;
					    @media (max-width: 1024px) {
							grid-template-columns: auto auto; 
						}
						@media (max-width: 460px) {
							grid-template-columns: auto;
						}
					   
					    .item-feature {
					    	display: flex;
						    align-items: center;
						    transition: all .3s ease; 

					    	label {
	                            font-size: 18px;
						    	font-weight: 500;
						    	line-height: 1.2;
						    	color: var(--heading)
					    	}
					    	
		                    i {
		                    	color: var(--primary);
		                    	font-size: 28px;
		                    	line-height: 1;
		                    	margin-right: 10px;
		                    }
					    }
					}
                }

                .room-detail-btn {
	                @media (max-width: 1024px) {
						margin-bottom: 60px;
					} 
                	a {
                		display: flex;
	                	justify-content: center;
	                	align-items: center;
	                	transition: all .3s ease;
	                	font-weight: 600;
						font-size: 19px;
						line-height: 1.2;
						text-transform: uppercase;
						color: var(--primary);
						padding: 18px;
						background-color: #fff;
						border-radius: 10px;
						border: 1px solid var(--primary);
						i {
							font-size: 30px;
							line-height: 1;
							margin-left: 12px;
						}
                	}
          
					&:hover {
						a {
                            transition: all .3s ease;
						    background-color: var(--primary);
						    color: #fff;
						}
					}
                }

			}
			.room-tabs {
				width: 46.5%;
				@media (max-width: 1024px) {
					width: 100%;
				}
			}
		}

		.booking-room-close {
			margin: 44px 0;
			.room-close {
				display: flex;
                justify-content: center;
				align-items: center;
				padding: 10px;
				border-radius: 0 0 20px 20px;
				background-color: var(--primary);
				i {
					font-size: 28px;
					cursor: pointer;
					color: #fff;
				}
			}
		}
	}

	&.booking-on-page {
		.wrapper-room {
			.room-img {
				a {
					pointer-events: none;
				}
			}
		}
	}
}