.ovabrw-search2 {
	text-align: center;
	background: #FFFFFF;
	box-shadow: 0px 16px 46px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	max-width: 481px;
	margin: 0 auto;

	.booking-title{
		margin: 0; 
		padding: 28px 0;
		font-weight: 500;
		font-size: 20px;
		line-height: 1.2;
		color: #063C43;
		border-bottom: 1px solid rgba(128, 128, 128, 0.2);
	}

	.ovabrw-search2-form {

		.ovabrw-s-field {
			display: grid;
			grid-gap: 50px; 
			grid-template-columns: 1fr 1fr;
			padding: 45px 56px 60px 56px;

			@media (max-width: 767px) {
				grid-template-columns: auto;
				grid-gap: 30px;
				padding: 40px 25px;			
			}

			.search-field {
				.ovabrw-label {
					display: flex;
				    align-items: center;
				    justify-content: flex-start;
				    margin-bottom: 10px;

					i {
						font-size: 24px;
						color: var(--primary);
						margin-right: 8px;
					}
					.label {
						font-weight: 500;
						font-size: 18px;
						line-height: 22px;
					}
				}

				.ovabrw-input {
					display: flex;
					align-items: center;
					position: relative;

                    // select
					select:not([size]):not([multiple]) {
						background-image: none;
					}

					select {
						&:invalid {
							opacity: 0.5;
						}
					}

					input[type="text"], select {
						border: none;
						padding: 0;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						color: var(--heading);
						max-width: 136px;
						cursor: pointer;
						background-color: transparent;
						@media (max-width: 767px) {
							max-width: 100%; 
						}
					}

					i {
						position: absolute;
						right: 0;
						font-size: 10px;
						color: var(--heading);
						font-weight: 600;
					}
				}

				.ovabrw-guestspicker-content {
					display: none;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					width: 200px;
					margin-top: 5px;
					left: 0;
					z-index: 1;
					padding: 16px;
					background-color: #FFFFFF;
					border: 1px solid #CACACA;
					box-sizing: border-box;
					box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
					border-radius: 12px;

					&:before {
						top: -7px;
						left: 50%;
						margin-left: -3.5px;
					    border-right: 7px solid transparent;
					    border-left: 7px solid transparent;
					    border-bottom: 7px solid var(--primary);
					    position: absolute;
					    display: inline-block;
					    content: '';
					}

					.guests-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.description {
							label {
                                font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: #000000;
							}
						}

						.guests-button {
							display: flex;
							align-items: center;

							input {
								width: 30px;
								height: 30px;
								padding: 0;
								text-align: center;
								border: none;
								pointer-events: none;
								font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: var(--heading);
							}

							.guests-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 25px;
    							height: 25px;
							    text-align: center;
							    border: none;
							    border-radius: 4px;
							    background-color: #F9F9F9;
							    color: var(--heading);
							    -webkit-transition: all .3s ease;
								-moz-transition: all .3s ease;
								-ms-transition: all .3s ease;
								transition: all .3s ease;

							    i {
							    	font-size: 10px;
							    }

							    &:hover {
							    	color: #FFF;
							    	background-color: var(--primary);
							    }
							}
						}

						&:first-child {
							padding-bottom: 16px;
							border-bottom: 1px solid #EEEEEE;
						}

						&:last-child {
							padding-top: 10px;
						}
					}
				}

				.ovabrw-guestspicker {
					cursor: pointer;

					.guestspicker {
						text-align: left;
						font-weight: 500;
						font-size: 20px;
						line-height: 22px;
						color: var(--heading);
						position: relative;

						i {
							position: absolute;
							right: 0;
							bottom: 3px;
							font-size: 10px;
							color: var(--heading);
							margin-left: 10px;
							vertical-align: middle;
							font-weight: 600;
						}
					}
				}

				&.guestspicker-control {
					position: relative;
					&.active {
						.ovabrw-guestspicker-content {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}

		.ovabrw-search2-btn {
			button.ovabrw-btn {
				border-radius: 8px;
				background-color: var(--primary);
				font-weight: 500;
				font-size: 22px;
				line-height: 1.2;
				color: #FFFFFF;
				border:unset;
				padding: 23px 95px;
				margin-bottom: 45px;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-ms-transition: all .3s ease;
				transition: all .3s ease;

				&:hover {
					background-color: #063c43;
				}
			}
		}
	}
}