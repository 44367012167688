.elementor-ralated-slide {
	.elementor-ralated {
		.owl-dots{
			text-align: center;
			margin-top: 80px;
			@media( max-width: 1200px ){
				margin-top: 40px;
			}
			.owl-dot{
				outline: none;
				span{
					width: 8px;
					height: 6px;
					margin: 5px;
					background: #58c2d0;
					display: block;
					-webkit-backface-visibility: visible;
					transition: opacity .2s ease;
					border-radius: 3px;
				}
				&.active{
					span{
						width: 31px;
						background: var(--primary);
						opacity: 1;
					}
				}
			}
		}
	}
}
