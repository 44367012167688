.ovabrw-search-room {
	background-color: #F9F9F9;
}

.ovabrw-search {
	width: max-content;
	@media (max-width: 1024px) {
		width: auto;
	}

	.ovabrw-search-form {
		display: flex;
		box-shadow: 0px 16px 46px rgba(0, 0, 0, 0.06);
		border-radius: 0px 20px;
		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.ovabrw-s-field {
			display: flex;
			padding: 38px 0 38px 45px;
			background: #fff;
			@media (max-width: 767px) {
				flex-direction: column;	
				padding: 40px 20px 10px 20px;			
			}

			.search-field {
				position: relative;
				padding: 0 43px;
				@media (max-width: 1024px) {
					padding: 30px;				
				}
				@media (max-width: 767px) {
					padding: 0 0 30px 0;				
				}

				&:not(:last-child) {
					border-right: 1px solid #CACACA;
					@media (max-width: 767px) {
						border-right: 0px solid #fff;				
					}
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					border-right: none;
				}

				.ovabrw-label {
					display: flex;
				    align-items: center;
				    justify-content: flex-start;
				    margin-bottom: 10px;

					i {
						font-size: 24px;
						color: var(--text);
						margin-right: 8px;
					}
					.label {
						font-weight: 500;
						font-size: 18px;
						line-height: 22px;
					}
				}

				.ovabrw-input {
					display: flex;
					align-items: center;
					position: relative;

					input[type="text"] {
						border: none;
						padding: 0;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						color: #063C43;
						max-width: 136px;
						cursor: pointer;
						background-color: transparent;
					}

					i {
						position: absolute;
						right: 0;
						font-size: 8px;
						font-weight: 600;
						color: #063c43;
					}
				}

				.ovabrw-guestspicker-content {
					display: none;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					width: 98%;
					margin-top: 5px;
					left: 0;
					z-index: 1;
					padding: 16px;
					background-color: #FFFFFF;
					border: 1px solid #CACACA;
					box-sizing: border-box;
					box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
					border-radius: 12px;

					&:before {
						top: -7px;
						left: 50%;
						margin-left: -3.5px;
					    border-right: 7px solid transparent;
					    border-left: 7px solid transparent;
					    border-bottom: 7px solid #efefef;
					    position: absolute;
					    display: inline-block;
					    content: '';
					}

					.guests-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.description {
							label {
                                font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: #000000;
							}
						}

						.guests-button {
							display: flex;
							align-items: center;

							input {
								width: 30px;
								height: 30px;
								padding: 0;
								text-align: center;
								border: none;
								pointer-events: none;
								font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: var(--heading);
							}

							.guests-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 25px;
    							height: 25px;
							    text-align: center;
							    border: none;
							    border-radius: 4px;
							    background-color: #F9F9F9;
							    color: var(--heading);
							    -webkit-transition: all .3s ease;
								-moz-transition: all .3s ease;
								-ms-transition: all .3s ease;
								transition: all .3s ease;

							    i {
							    	font-size: 10px;
							    }

							    &:hover {
							    	color: #FFF;
							    	background-color: var(--primary);
							    }
							}
						}

						&:first-child {
							padding-bottom: 16px;
							border-bottom: 1px solid #EEEEEE;
						}

						&:last-child {
							padding-top: 10px;
						}
					}
				}

				.ovabrw-guestspicker {
					cursor: pointer;

					.guestspicker {
						font-weight: 500;
						font-size: 20px;
						line-height: 22px;
						color: #063C43;
						@media (max-width: 767px) {
							display: flex;
							align-items: center;
							position: relative;
						}

						i {
							font-size: 8px;
							font-weight: 600;
							color: #000;
							margin-left: 10px;
							vertical-align: middle;
							@media (max-width: 767px) {
								position: absolute;
								right: 0;
							}
						}
						.gueststotal {
							display: inline-block;
							width: 11px;
							margin-right: 4px;
						}
					}
				}

				&.guestspicker-control {
					&.active {
						.ovabrw-guestspicker-content {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}

		.ovabrw-search-btn {
			@media (max-width: 767px) {
				margin: 0 auto;
			}
			button.ovabrw-btn {
				height: 100%;
				border-radius: 0px 20px 0px 0px;
				background-color: #063C43;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				color: #FFFFFF;
				border:unset;
				padding: 40px 86px;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-ms-transition: all .3s ease;
				transition: all .3s ease;

				@media (max-width: 767px) {
					margin-bottom: 45px;
					padding: 23px 95px;
					border-radius: 8px;   
				}

				&:hover {
					background-color: var(--primary);
				}
			}
		}
	}
}