.woocommerce-product-gallery {
	max-width: 600px;
    position: relative;
    margin-bottom: 2rem;
    .woocommerce-product-gallery__trigger {
    	position: absolute;
    	top: 1rem;
    	right: 1rem;
    	z-index: 99;
    }
    a {
    	img {
	    	width: 100%;
	    	height: auto;
	    }
	}
	.flex-viewport {
		margin-bottom: 20px;
	}
	.flex-control-nav.flex-control-thumbs {
		display: flex;
	    list-style: none;
	    justify-content: space-between;
	    margin: 0;
	}
}

.ova-product-img {
	ul.list-img {
		list-style: none;
		margin: 0;
		padding: 0;
		display: grid;
    	grid-template-columns: 1fr 1fr 1fr 1fr;
    	grid-gap: 30px;

    	.item-img {
    		overflow: hidden;
    		border-radius: 12px;
    		cursor: pointer;

    		&.featured-img {
    			grid-row: 1/span 2;
    			grid-column: 1/span 2;
    		}

    		&.gallery_blur {
				position: relative;

				.gallery-fancybox {
					.blur-bg {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background-color: rgba(0, 0, 0, 0.5);
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 28px;
						line-height: 34px;
						color: rgba(255, 255, 255, 0.9);
					}
				}
			}

			&.gallery_hidden {
				display: none;
			}

			.gallery-fancybox {
				display: flex;
				width: 100%;
				height: 100%;
				
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					border-radius: 12px;
				}
			}
    	}

    	@media screen and (max-width: 768px) {
    		grid-template-columns: 1fr 1fr;
    		grid-gap: 10px;
    	}
	}
}