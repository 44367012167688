/* Product infomation */
.ovabrw_product_info{
	text-align: center;
	.room-info {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 60px;
		@media( max-width: 1200px ){
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px
		}
		@media( max-width: 767px ){
			grid-template-columns: 1fr 1fr;
		}
		@media( max-width: 360px ){
			grid-template-columns: 1fr;
		}

		li{
			-o-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-webkit-transition: all .3s ease;
			transition: all .3s ease;
			text-align: center;
		    padding: 16px;
		    border-radius: 8px;
			color: var(--primary);
			span{
				color: #063c43;
				font-weight: 600;
			}
			i {
				display: block;
				font-size: 48px;
				margin-bottom: 8px; 
			}
			&:hover {
				-o-transition: all .3s ease;
				-ms-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.06);
			}
		}
	}
}